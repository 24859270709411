<template>
  <div class="file-an-appeal section container anchor-offset" id="file-an-appeal">
    <div class="row justify-content-center">
      <div class="col-sm-8 text-center">


      <div class="mt-5 mb-3"><h2>File An Appeal</h2></div>
        <p class="lead">
          Students are strongly encouraged to work with their Student Success
          Counselors to complete the forms before submitting. Incomplete forms will
          be rejected.
        </p>
      </div><!--end col-->

      <div class="col-sm-10 mt-3">

        
        <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
              <img src="../assets/draft1.jpg" class="cover" alt="">
            </div>
            <div class="col-md-10">
              <div class="card-body">
                <h3 class="card-title">Grade Appeal</h3>
                <p class="card-text">Grade appeals are available when students can demonstrate their assignments were evaluated outside the written directions, rubric, or standards not applied to other students.</p>
                <p class="card-text">Prior to considering a grade appeal, students are expected to:</p>
                  <ul class="mb-4">
                  <li> discuss course-related questions or concerns with their instructor directly so that the instructor has the opportunity to work with the student to seek resolution</li>
                  <li> contact their student success counselor as soon as questions or concerns arise so that administration has an opportunity to help facilitate immediate resolution prior to the end of the course</li>
                  </ul>

                <p class="card-text">
                  <router-link to="/appeals/grade-appeals" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i> Start Grade Appeal</router-link>
                </p>
              </div>
            </div>
          </div>
        </div><!--end card-->

        <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
              <img src="../assets/draft2.jpg" class="cover" alt="">
            </div>
            <div class="col-md-10">
            <div class="card-body">
                    <h3 class="card-title">Tuition Appeal</h3>
                    <p class="card-text">
                Tuition appeals are requests for an adjustment to tuition and fee charges when a student can document extenuating circumstances.
                    </p> 
                    <p class="card-text">Situations that are <u>not</u> considered extenuating include, but are not limited to predictable events such as: </p>
                      <ul class="mb-4">
                        <li>a wedding</li>
                          <li>vacation</li>
                          <li>shift in work assignment</li>
                          <li>previously scheduled surgery</li>
                          <li> or other events of which the student is aware and can adjust their schedule prior to the term drop deadline.</li>
                          </ul>
                

                    <p class="card-text">
                      <router-link to="/appeals/tuition-appeals" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start Tuition Appeal</router-link>
                    </p>              
              </div>
            </div>
          </div>
        </div><!--end card-->


        <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
                <img src="../assets/draft3.jpg" class="cover" alt="">

            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">SAP Probation Appeal</h3>
                      <p class="card-text">
                        Students will be placed on SAP Probation if they fail to meet the minimum SAP standards after the SAP Warning trimester. </p><p class="card-text">SAP Probation status is noted on the transcript until the student returns to Good Academic Standing. Students on SAP Probation must submit and have an appeal approved to continue with courses. </p>

                      <p class="card-text">
                        <router-link to="/appeals/sap-probation" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start SAP Probation Appeal</router-link>
                      </p>                  
              </div>
            </div>
          </div>
        </div><!--end card-->

          <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
              <img src="https://images.prismic.io/csug/78581335-c075-4b7f-a6ad-2cf601781ea1_appeals-financial-aid-front-cap.png" class="cover" alt="">
            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">Financial Aid Reinstatement Appeal</h3>
                      <p class="card-text">
                        Students on SAP Probation are ineligible to receive financial aid. Students who can provide documentation demonstrating they faced an unexpected obstacle during their SAP Warning term may file an appeal to have financial aid reinstated. </p>

                      <p class="card-text mt-auto">
                        <router-link to="/appeals/sap-financial-aid" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Learn More </router-link>
                      </p>                  
              </div>
            </div>
          </div>
        </div><!--end card-->

          <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">             
               <img src="../assets/draft5.jpg" class="cover" alt="">
            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">Suspension Appeal</h3>
                      <p class="card-text">
                        Students who fail to return to Good Academic Standing following their SAP Probation period will be suspended from the university. Suspended students who wish to re-enroll will need to appeal and provide information that demonstrates their ability to be successful academically. </p>

                      <p class="card-text mt-auto">
                        <router-link to="/appeals/sap-suspension" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start Suspension Appeal</router-link>
                      </p>                  
              </div>
            </div>
          </div>
        </div><!--end card-->

        <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
                <img src="https://images.prismic.io/csug/9de9fffd-993e-4bd6-9015-e639a080fbf8_appeals-retroactivewithdrawal-front-cap.png" class="cover" alt="">

            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">Retroactive Withdrawal Appeal</h3>
                      <p class="card-text"> 
                       Students are expected to follow all term deadlines. 
                      </p>
                      <p class="card-text"> 
                      In extenuating circumstances, students who encounter an unexpected obstacle after the withdrawal deadline of a term can appeal to earn a “W” for courses in that term as opposed to their earned letter grade. 
                      </p>

                      <p class="card-text mt-auto">
                        <router-link to="/appeals/retroactive-withdrawal" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start Retroactive Withdrawal Appeal</router-link>
                      </p>
              </div>
            </div>
          </div>
        </div><!--end card-->

        <!--card-->
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
              <img src="../assets/draft7.jpg" class="cover" alt="">
            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">Maximum Time Frame Appeal</h3>
                      <p class="card-text"> 
                       Students are able to attempt up to 150% of the credits required to complete their degree. Students who need to exceed the 150% attempted credits to graduate will exceed the Maximum Time Frame policy.
                      </p>

                      <p class="card-text mt-auto">
                        <router-link to="/appeals/sap-max-time-frame" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start Maximum Time Frame Appeal</router-link>
                      </p>
              </div>
            </div>
          </div>
        </div><!--end card-->


        <!--card--> 
        <div class="card mb-3">
          <div class="row g-0 d-flex align-content-stretch flex-wrap">
            <div class="col-md-2 d-none d-md-inline">
              <img src="../assets/draft8.jpg" class="cover" alt="">
            </div>
            <div class="col-md-10">
              <div class="card-body">
                      <h3 class="card-title">Provost Appeal</h3>
                      <p class="card-text">
                The Provost Appeal can review the decision of the appeals committee or department in order to ensure previous decisions were made in accordance with university policy.
                  </p>

                  <p class="card-text">
                The Provost appeal is not meant to replace determinations of the appeals committee or departments, but to affirm a student's right to due process was properly conducted.
                  </p>

                      <p class="card-text mt-auto">
                        <router-link to="/appeals/provost-appeals" class="d-inline-flex align-items-center text-decoration-none"><i class="bi bi-arrow-right-circle-fill fs-3 me-2"></i>Start Provost Appeal</router-link>
                      </p>
              </div>
            </div>
          </div>
        </div><!--end card-->
        


    </div> <!--end col-->
  </div><!--end row-->
  </div><!-- end contain-->
  <!--End banner-->
</template>

<script>
export default {
  name: "FileAnAppealBrief",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.file-an-appeal h2 {
  color:#9e1831;
  font-weight:600;
}

#app .card {
  background-color:#eee;
  border: 0px;
}

.card-title {
  color: #9e1831;
  font-weight: 600;
}
.card-body {
  padding:2rem;
  display:flex;
  flex-direction: column;
  height:100%
}

.card-text a {
  font-weight:600;
}

//yo-ho-ho
.anchor-offset { 
  scroll-margin-top: 100px
}
</style>
